<template>
    <nav>
        <h4>Data Quiz</h4>
        <button @click="sendLanguage">
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="86" viewBox="0 0 74 86">
                <g id="hexagon" data-name="hexagon" transform="translate(74) rotate(90)">
                    <path d="M64.5,0,86,37,64.5,74h-43L0,37,21.5,0Z" stroke="none" />
                    <path
                        d="M 22.65099716186523 2 L 2.313148498535156 36.99997711181641 L 22.65097045898438 72 L 63.3489990234375 72 L 83.68685913085938 37 L 63.3489990234375 2 L 22.65099716186523 2 M 21.50001525878906 0 L 64.49997711181641 0 L 86 37 L 64.49997711181641 74 L 21.49999237060547 74 L 7.62939453125e-06 36.99997329711914 L 21.50001525878906 0 Z"
                        stroke="none" fill="#fff" />
                </g>
            </svg>
            {{this.lang == "DE"?"DE":"EN"}}
        </button>
    </nav>
</template>

<script>

export default {
    name: 'NavigationBar',
    emits: ["languageData"],
    props: [
        'lang',
    ],
    methods: {
        sendLanguage() {
            let lang = (this.lang == 'DE') ? 'EN' : 'DE';
            this.$emit('languageData', {"lang" : lang});
        },
    },
}
</script>