<template>
    <ul class="high-score">
        <h3>Highscore</h3>

        <div class="high-score-card" v-for="player in playersSorted" :key="player.id" :class="player.id == id ? 'highlight' : ''">
            <p class="rank">{{ player.r }}</p>

            <div class="avatar-container">
                <div class="avatar-small" :style="{ background: colors[player.c], clipPath: polygons[player.e - 4] }"></div>
                <h5 class="overlay-small">{{ player.i }}</h5>
            </div>

            <p class="score">{{ player.s }} <span v-if="player.s != 1">{{ $t('points') }}</span><span v-if="player.s == 1">{{ $t('point') }}</span>
            </p>
        </div>
        <br>
    </ul>
</template>

<script>

export default {
    name: 'ShowHighScore',
    props: [
        'players',
        'id',
    ],
    data() {
        return {
            playersSorted: null,
            colors: [
                '#FA64FA',
                '#0096FA',
                '#FFC800',
                '#96C8C8',
                '#FA6464',
                '#64C896',
                '#966496',
                '#DCDC78',
                '#FAAA32'
            ],

            polygons: [
                "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                "polygon(50% 0%, 100% 38%, 82% 95%, 18% 95%, 0% 38%)",
                "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
                "polygon(50% 0%, 90% 20%, 100% 60%, 75% 95%, 25% 95%, 0% 60%, 10% 20%)",
                "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                "polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)",
                "polygon(50% 0%, 80% 10%, 98% 35%, 98% 65%, 80% 90%, 50% 100%, 20% 90%, 2% 65%, 2% 35%, 20% 10%)",
            ],
        }
    },
    mounted() {
        this.playersSorted = [...this.players];
        this.playersSorted.sort((a, b) => b.s - a.s);

        let rank = 0;
        let top = null;
        for (const [i, player] of this.playersSorted.entries()) {
            rank = (player.s == top) ? rank : i + 1;
            player.r = rank;
            top = player.s;
        }
    },
}
</script>