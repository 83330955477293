<template>
    <div class="start-screen">
        <div class="video-container">
            <video autoplay preload loop muted playsinline
                poster="@/includes/media/231020-HdD-Website-Home-preview-tinyPNG.png">
                <source id="mp4" src="@/includes/media/231018-HdD-Website-HomeBG-V03-vertical.mp4" type="video/mp4">
                <!--<source src="@/includes/media/231018-HdD-Website-HomeBG-V03-VP9-vertical.webm" type="video/webm">-->
            </video>
        </div>

        <div class="main">
            <h1>Quiz</h1>
            <p>
                {{ $t('quizDescription') }}
            </p>
            <v-btn color="primary" @click="init">{{ $t('startQuiz') }}</v-btn>
        </div>
    </div>
</template>

<script>

export default {
    name: 'StartScreen',
    emits: ["closeStartScreen"],
    props: [
        'lang',
    ],
    methods: {
        init() {
            this.$emit('closeStartScreen', { "state": true });
        },
    },
}
</script>