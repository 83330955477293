<template>
    <div class="score">
        <h3>{{ $t('yourScore') }}</h3>
        <v-progress-circular class="score-visual" :color="color" :model-value="progress" :size="200"
            :width="20"></v-progress-circular>

        <div class="center">
            <div class="avatar-small" :style="{ background: color, clipPath: polygons[edges - 4] }"></div>
            <h5 class="overlay-small">{{ initials }}</h5>
        </div>

        <p class="percentage" :style="{ color: color }">{{ percentage }}<span>%</span></p>
        <p v-if="score == 0">{{ $t('noCorrectAnswers') }}</p>
        <p v-else-if="score == 1 && questionCount == 1">{{ $t('oneCorrectAnswer') }}</p>
        <p v-else>{{ $t('multipleCorrectAnswers', { score: score, questionCount: questionCount }) }}</p>
    </div>
</template>

<script>

export default {
    name: 'ShowScore',
    props: [
        'score',
        'questionCount',
        'color',
        'edges',
        'initials',
    ],
    data() {
        return {
            percentage: 0,
            progress: 0,
            messages: [
                "Schade! Leider hast du keine Frage richtig beantwortet.",
                "Gratuliere! Du hast " + this.score + " von " + this.questionCount + " Fragen richtig beantwortet.",
                "Gratuliere! Du hast eine Frage richtig beantwortet."
            ],

            polygons: [
                "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                "polygon(50% 0%, 100% 38%, 82% 95%, 18% 95%, 0% 38%)",
                "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
                "polygon(50% 0%, 90% 20%, 100% 60%, 75% 95%, 25% 95%, 0% 60%, 10% 20%)",
                "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                "polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)",
                "polygon(50% 0%, 80% 10%, 98% 35%, 98% 65%, 80% 90%, 50% 100%, 20% 90%, 2% 65%, 2% 35%, 20% 10%)",
            ],
        }
    },
    mounted() {
        this.percentage = Math.round(this.score / this.questionCount * 100);
        this.percentage = (this.score == 0 && this.questionCount == 0) ? 0 : this.percentage;
        setInterval(this.progressValue, 35);
    },
    methods: {
        progressValue() {
            if (this.progress < this.percentage) this.progress = this.progress + 3;
        }
    },
}
</script>