import { createI18n } from 'vue-i18n';

// Import your language files
import de from './de.js';
import en from './en.js';

const i18n = createI18n({
  locale: 'de',
  messages: {
    de,
    en,
  },
});

export default i18n;
