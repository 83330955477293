<template>
    <div class="pause-message">
        <div class="pause">
            <img src="@/includes/shapes/pixel_pause_center.svg" />
            <h6>{{ $t('soon') }}<br>{{ $t('continue') }}</h6>

            <ParticleAnimation source="pause"/>
        </div>
    </div>
</template>

<script>
import ParticleAnimation from '@/components/ParticleAnimation.vue'

export default {
    name: 'PauseGame',
    components: {
        ParticleAnimation
    },
}
</script>