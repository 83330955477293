<template>
    <div class="answer-message">
        <div v-if="isCorrect" class="correct">
            <img src="@/includes/shapes/pixel_correct_center.svg"/> 
            <h6>{{ $t('correct2') }}<br>{{ $t('answer') }}</h6>
            <ParticleAnimation source="correct"/>
        </div>
        <div v-if="!isCorrect" class="wrong">
            <img src="@/includes/shapes/pixel_wrong_center.svg"/> 
            <h6>{{ $t('sorry') }}<br>{{ $t('wrong') }}</h6>
            <ParticleAnimation source="wrong"/>
        </div>
    </div>
</template>
<script>
import ParticleAnimation from '@/components/ParticleAnimation.vue'

export default {
    name: 'AnswerCheck',
    emits: ["updateScore"],
    components: {
        ParticleAnimation
    },
    props: [
        'answer',
        'x',
        'y',
    ],
    data() {
        return {
            isCorrect: false,

            answerBoxWidth: 700,
            answerBoxHeight: 700,

            answerBox01: {
                x: 75, //75
                y: 608,
            },
            answerBox02: {
                x: 900, //900
                y: 608,
            },
            answerBox03: {
                x: 1725, //1725
                y: 608,
            },
        }
    },
    mounted() {
        this.checkAnswer();
    },
    unmounted() {
        this.$emit('updateScore', { "scored": this.isCorrect });
    },
    methods: {
        checkAnswer() {
            let selectedAnswer = [this.isInside(this.answerBox01), this.isInside(this.answerBox02), this.isInside(this.answerBox03)];
            if (selectedAnswer[parseInt(this.answer)]) this.isCorrect = true;
        },

        isInside(box) {
            return this.x >= box.x && this.x <= (box.x + this.answerBoxWidth) && this.y >= box.y && this.y <= (box.y + this.answerBoxHeight);
        },
    },
}
</script>