<template>
  <NavigationBar :lang="lang" @languageData="changeLanguage" />
  <StartScreen v-if="init == false && identified" @closeStartScreen="changeInit" />
  <ThankYou v-if="!identified" />

  <main v-if="init == true && identified">
    <AvatarCustomization v-if="id == ''" @avatarData="sendAvatar" />

    <div v-if="id != '' && !exit && state != 4">
      <h2>{{ $t('yourDataPixel') }}</h2>
      <div class="avatar-small" :style="{ background: colors[color], clipPath: polygons[edges - 4] }"></div>
      <h5 class="overlay-small">{{ initials }}</h5>
      <p class="score-info">{{ score }} / {{ questionCount }} {{ $t('correct') }}</p>

      <div class="help" @click="help = true">
        <img src="@/includes/shapes/help_icon.svg" />
        <p>{{ $t('showHelp') }}</p>
      </div>

      <div v-if="help" class="message-overlay">
        <div class="message-box">
          <h3>{{ $t('explanation') }}</h3>
          <p>{{ $t('explanation_top') }}</p>
          <video autoplay preload loop muted playsinline poster="@/includes/media/joystick.png">
            <source src="@/includes/media/joystick.mp4" type='video/mp4;'>
          </video>
          <p>{{ $t('explanation_bottom') }}</p>
          <button @click="help = false">{{ $t('understood') }}</button>
        </div>
      </div>

      <GamePad v-if="(!exitMessage && !help && !ledMessage) && (state == 0 || state == 1 || state == -1)"
        @movementData="updateCoordinates" />
      <v-btn v-if="mode == 0" @click="exitMessage = true" class="exit-button" color="black">{{ $t('stopPlaying')
      }}</v-btn>

      <div v-if="exitMessage" class="message-overlay">
        <div class="message-box">
          <h3>{{ $t('finishPlaying') }}</h3>
          <p>{{ $t('finishMessage') }}</p>
          <div class="overlay-buttons">
            <button @click="exitMessage = false">{{ $t('back') }}</button>
            <button @click="exitGame">{{ $t('quitGame') }}</button>
          </div>
        </div>
      </div>

      <div v-if="ledMessage" class="message-overlay">
        <div class="message-box">
          <h3>{{ $t('playingField') }}</h3>
          <p>{{ $t('playingFieldMessage') }}</p>
          <button @click="checkFieldMessage">{{ $t('playingFieldButton') }}</button>
        </div>
      </div>

      <AnswerCheck v-if="(state == 2 && !help && !exitMessage) || (mode == 0 && state == 3 && !help && !exitMessage)"
        :answer="answer" :x="x" :y="y" @updateScore="updateScore" />
      <PauseGame v-if="state == 3 && mode == 1 && !help" />
    </div>

    <ShowScore v-if="exit" :score="finalScore" :questionCount="questionCount" :color="colors[color]" :edges="edges"
      :initials="initials" />
    <ShowHighScore :players="players" :id="id" v-if="state == 4 && mode == 1" />
  </main>
</template>

<script>
import NoSleep from 'nosleep.js';
import NavigationBar from '@/components/NavigationBar.vue'
import StartScreen from '@/components/StartScreen.vue'
import AvatarCustomization from '@/components/AvatarCustomization.vue'
import GamePad from '@/components/GamePad.vue'
import AnswerCheck from '@/components/AnswerCheck.vue'
import PauseGame from '@/components/PauseGame.vue'
import ShowScore from '@/components/ShowScore.vue'
import ShowHighScore from '@/components/ShowHighScore.vue'
import ThankYou from '@/components/ThankYou.vue'

export default {
  name: 'HomeView',
  components: {
    NavigationBar,
    StartScreen,
    AvatarCustomization,
    GamePad,
    AnswerCheck,
    PauseGame,
    ShowScore,
    ShowHighScore,
    ThankYou,
  },
  data() {
    return {
      identified: false,
      secret: "lebkuchen",
      payload: "",
      hash: "",

      init: false,
      lang: this.$i18n.locale.toUpperCase(),
      id: '',
      initials: '',
      edges: '',
      color: '',
      x: -1,
      y: -1,

      score: 0,
      finalScore: 0,
      currentQuestion: 0,
      questionCount: 0,

      mode: -1, // 0 -> individual, 1 -> group
      state: 1, // -1 -> off, 0 -> init, 1 -> question, 2 -> answer, 3 -> break, 4 -> high score
      question: "",
      answer: -1,
      exit: false,
      exitMessage: false,
      help: false,
      ledMessage: true,
      players: null,
      resetPosition: false,
      noSleep: null,

      // playable area
      // 2500 x 1440 - character size
      field: {
        x: 2500,
        y: 1440,
      },

      connection: {
        connection_url: "wss://hdd-quiz.cms.re-spaces.com/",
        connection_ready: false,
        connection_error: false,
        websocket: null,
        keepAlive: null,
      },

      colors: [
        '#FA64FA',
        '#0096FA',
        '#FFC800',
        '#96C8C8',
        '#FA6464',
        '#64C896',
        '#966496',
        '#DCDC78',
        '#FAAA32'
      ],

      polygons: [
        "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
        "polygon(50% 0%, 100% 38%, 82% 95%, 18% 95%, 0% 38%)",
        "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
        "polygon(50% 0%, 90% 20%, 100% 60%, 75% 95%, 25% 95%, 0% 60%, 10% 20%)",
        "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
        "polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)",
        "polygon(50% 0%, 80% 10%, 98% 35%, 98% 65%, 80% 90%, 50% 100%, 20% 90%, 2% 65%, 2% 35%, 20% 10%)",
      ],
    }
  },
  watch: {
    lang(lang) {
      this.$i18n.locale = lang.toLowerCase();
    },
  },
  mounted() {
    this.noSleep = new NoSleep();

    this.getHash();
    this.generatePayload();

    this.initConnection();

    // check if it is known user and load data
    if (sessionStorage.init) this.init = (sessionStorage.init == "true") ? true : false;
    if (sessionStorage.ledMessage) this.ledMessage = (sessionStorage.ledMessage == "true") ? true : false;
    if (sessionStorage.id) this.id = sessionStorage.id;
    if (sessionStorage.initials) this.initials = sessionStorage.initials;
    if (sessionStorage.edges) this.edges = parseInt(sessionStorage.edges);
    if (sessionStorage.color) this.color = parseInt(sessionStorage.color);
    if (sessionStorage.x) this.x = parseInt(sessionStorage.x);
    if (sessionStorage.y) this.y = parseInt(sessionStorage.y);
    if (sessionStorage.score) this.score = parseInt(sessionStorage.score);
    if (sessionStorage.questionCount) this.questionCount = parseInt(sessionStorage.questionCount);

    //window.addEventListener('visibilitychange', this.reconnect);
  },
  created() {
    document.addEventListener('touchstart', this.enableNoSleep, false);
  },
  unmounted() {
    if (this.connection_ready) {
      this.websocket.close();
      this.noSleep.disable();
    }
  },
  methods: {
    enableNoSleep() {
      this.noSleep.enable();
    },

    generatePayload() {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate, 0);
      let payload = formattedDate;

      this.verify(payload);

      payload = this.formatDate(currentDate, 1);
      this.verify(payload);

      payload = this.formatDate(currentDate, 2);
      this.verify(payload);
    },

    formatDate(date, checkPreviousHour) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Month is zero-based
      const day = date.getDate();
      const hours = date.getHours() - checkPreviousHour;

      // Ensure two digits for month, day, hours, and minutes
      const formattedMonth = String(month).padStart(2, '0');
      const formattedDay = String(day).padStart(2, '0');
      const formattedHours = String(hours).padStart(2, '0');

      // Format: yyyyMdhhmm
      return `${year}${formattedMonth}${formattedDay}${formattedHours}`;
    },

    getHash() {
      const url = window.location.href;
      const hashIndex = url.lastIndexOf('#');
      this.hash = hashIndex !== -1 ? url.substring(hashIndex + 1) : '';
    },

    async verify(payload) {
      try {
        const encoder = new TextEncoder();
        let data = encoder.encode(payload + this.secret);

        const hashBuffer = await crypto.subtle.digest('SHA-1', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const signature = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        const value = payload + '-' + signature;
        console.log(this.hash + " / " + value);
        if (this.hash === value) {
          this.identified = true;
        }

      } catch (error) {
        console.error('Error in payload:', error);
      }
    },

    initConnection() {
      if (!this.exit) {
        this.websocket = new WebSocket(this.connection.connection_url);
        this.websocket.onopen = this.onSocketOpen;
        this.websocket.onmessage = this.onSocketMessage;
        this.websocket.onerror = this.onSockerError;
        this.websocket.onclose = this.reopenSocket;
      }
    },

    onSocketOpen() {
      this.connection_ready = true;
      this.keepAlive = setInterval(this.sendAliveMessage, 29000);
      console.log("connected");
    },

    onSocketMessage(evt) {
      console.log('received: ' + evt.data);
      this.processMessage(evt.data);
    },

    onSockerError(evt) {
      this.connection_error = true;
      console.log('error: ' + evt.data);
    },

    reopenSocket() {
      if (!this.exit) {
        if (this.websocket == null) {
          window.location.reload();
        }
        this.websocket = null;
        setTimeout(this.initConnection, 500);
      }
    },

    sendAliveMessage() {
      if (this.id != "") {
        this.sendMessage();
      }
    },

    /*reconnect() {
      console.log("reconnecting");
      if (!this.connection_ready && document.visibilityState === "visible") this.initConnection;
    },*/

    sendMessage() {
      if (this.websocket == null) {
        console.log('null');
        this.initConnection
        return;
      }

      let msg = {
        "topic": "user", "data": {
          "id": this.id,
          "i": this.initials,
          "e": this.edges,
          "c": this.color,
          "x": this.x,
          "y": this.y,
          "s": this.score,
          "qc": this.questionCount,
        }
      };
      this.websocket.send(JSON.stringify(msg));
      clearInterval(this.keepAlive);
      this.keepAlive = setInterval(this.sendAliveMessage, 29000);
    },

    sendAvatar(data) {
      this.initials = data.i;
      this.edges = data.e;
      this.color = data.c;

      this.id = this.generateID();
      this.x = this.generateCoordinates(this.field.x);
      this.y = this.generateCoordinates(this.field.y);

      // save values to session storage
      sessionStorage.id = this.id;
      sessionStorage.initials = this.initials;
      sessionStorage.edges = this.edges;
      sessionStorage.color = this.color;
      sessionStorage.x = this.x;
      sessionStorage.y = this.y;
      sessionStorage.score = this.score;

      this.sendMessage();
    },

    processMessage(msg) {
      let message = JSON.parse(msg);

      // assigning state, mode, current question and answers based on server message
      typeof message.quiz.state.s !== 'undefined' && (this.state = message.quiz.state.s);
      typeof message.quiz.state.m !== 'undefined' && (this.mode = message.quiz.state.m);
      typeof message.quiz.state.q !== 'undefined' && (this.question = message.quiz.state.q);
      typeof message.quiz.state.a !== 'undefined' && (this.processAnswer(message.quiz.state.a));
      typeof message.quiz.users !== 'undefined' && (this.players = message.quiz.users);

      // check if mode changed
      if (this.mode != sessionStorage.mode) {
        this.resetScore();
        sessionStorage.mode = this.mode;
      }

      if (this.state == 3 && this.resetPosition == false && this.id != "") {
        this.resetPosition = true;
        this.y = 1330;
        sessionStorage.y = this.y;
        this.sendMessage();
      } else if (this.state != 3) {
        this.resetPosition = false;
      }
    },

    processAnswer(answers) {
      if (answers == "") return;
      this.answer = answers.findIndex(getCorrectAnswer);

      function getCorrectAnswer(answer) {
        return answer.c == 1;
      }
    },

    generateID() {
      return "id" + Math.random().toString(16).slice(2);
    },

    generateCoordinates(max) {
      return Math.floor(Math.random() * (max - 0 + 1) + 0);
    },

    updateCoordinates(movement) {
      let posX = this.x;
      let posY = this.y;

      this.x += movement.x;
      this.y += movement.y;

      if (this.x < 0) this.x = 0;
      if (this.y < 0) this.y = 0;

      if (this.x > this.field.x) this.x = this.field.x;
      if (this.y > this.field.y) this.y = this.field.y;

      if (this.x != posX || this.y != posY) {
        sessionStorage.x = this.x;
        sessionStorage.y = this.y;
        this.sendMessage();
      }
    },

    updateScore(correct) {
      if (this.id != "") {
        this.questionCount++;
        sessionStorage.questionCount = this.questionCount;

        console.log(correct);
        if (correct.scored) this.score++;
        sessionStorage.score = this.score;
        this.sendMessage();
      }
    },

    resetScore() {
      this.score = 0;
      this.questionCount = 0;
      sessionStorage.score = 0;
      sessionStorage.questionCount = 0;
    },

    exitGame() {
      this.exit = true;
      this.finalScore = this.score;
      this.websocket.close();
      sessionStorage.clear();
    },

    changeLanguage(data) {
      this.lang = data.lang;
    },

    changeInit(data) {
      this.init = data.state;
      sessionStorage.init = this.init;
    },

    checkFieldMessage() {
      this.ledMessage = false;
      sessionStorage.ledMessage = this.ledMessage;
    },
  },
}
</script>

<style>
@import url("assets/styles.css");
</style>
