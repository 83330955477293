import { createApp } from 'vue'
import App from './App.vue'

// Translation
import i18n from './language/i18n';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const smartDataTheme = {
  dark: true,
  colors: {
    background: '#FFFFFF',
    primary: '#6260BF',
    error: '#FA6464',
    orange: '#FAAA32',
    lime: '#DCDC78',
    purple: '#966496',
    green: '#64C896',
    red: '#FA6464',
    hyperblue: '#96C8C8',
    cyan: '#0096FA',
    pink: '#FA64FA',
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'smartDataTheme',
    themes: {
      smartDataTheme,
    },
  },
  defaults: {
    VBtn: {
      variant: 'flat',
      rounded: 0,
      height: 42,
    },
  },
})

const messages = {
  de: require('./language/de.js'),
  en: require('./language/en.js'),
};


createApp(App).use(vuetify).use(i18n, { messages }).mount('#app')
