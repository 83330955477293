<template>
    <div class="particles">
        <img v-for="particle in 20" :key="'p-' + particle" class="particle"
            :style="{ top: particle * 12 + '%', left: getRandom() + '%', transform: 'scale(' + getScale() + ')' }" :src="require('@/includes/shapes/pixel_' + source + '.svg')">
    </div>
</template>

<script>

export default {
    name: 'ParticleAnimation',
    props: [
        'source',
    ],
    methods: {
        getRandom() {
            return Math.floor(Math.random() * (150 - 0) + 0);
        },

        getScale() {
            return Math.floor((Math.random() * (7 - 3) + 3)) / 10;
        },
    },
}
</script>