<template>
    <div class="game-pad" ref="game-pad-position"></div>
    <div class="game-pad-background">
        <img src="@/includes/shapes/game_pad_background.svg"/>
    </div>
    <div class="game-pad-spacing"></div>   
</template>

<script>
import nipplejs from 'nipplejs';

export default {
    name: 'GamePad',
    emits: ["movementData"],
    data() {
        return {
            position: 0,
            moveX: 0,
            moveY: 0,
            maxSpeed: 20,
            boost: 15, 
            joystick: null,
            interval: null,
            duration: 50, // change to control amount of messages send
        }
    },
    mounted() {
        window.addEventListener("resize", this.resize);
        this.position = this.$refs["game-pad-position"].getBoundingClientRect().top +  window.scrollY + 100;
        this.initJoystick();
    },
    unmounted() {
        window.removeEventListener("resize", this.resize);
        this.joystick.destroy();
    },
    methods: {
        initJoystick() {
            let settings = {
                mode: 'static',
                position: {
                    left: '50%',
                    top: this.position + 'px',
                },
                zone: this.$refs["game-pad-position"],
                dynamicPage: true,
                size: 100 * 2,
                color: 'white'
            };

            this.joystick = nipplejs.create(settings);

            this.joystick.on('move', (e, data) => {
                this.moveX = this.clamp(this.boost * data.force * Math.cos(data.angle.radian), -this.maxSpeed, this.maxSpeed);
                this.moveY = -1 * this.clamp(this.boost * data.force * Math.sin(data.angle.radian), -this.maxSpeed, this.maxSpeed);
            })

            this.joystick.on("start", () => {
                this.interval = setInterval(() => {
                    this.$emit('movementData', { "x": Math.round(this.moveX), "y": Math.round(this.moveY) });
                }, this.duration)
            })

            this.joystick.on('end', () => {
                clearInterval(this.interval);
            })
        },

        clamp(num, min, max) {
            return num <= min ? min : num >= max ? max : num;
        },

        resize() {
            this.joystick.destroy();
            this.position = this.$refs["game-pad-position"].getBoundingClientRect().top +  window.scrollY + 100;
            this.initJoystick();
        },
    },
}
</script>