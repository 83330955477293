<template>
    <h2>{{ $t('createYourDataPixel') }}</h2>
    <div class="avatar" :style="{ background: colors[color], clipPath: polygons[edges - 4] }"></div>
    <h3 class="overlay">{{ initials }}</h3>

    <v-form class="settings" ref="entryForm">
        <div class="edges-selection">
            <img src="@/includes/shapes/arrow_button.svg" @click="reduceEdges" v-bind:class="(edges < 5) ? 'disabled' : ''">
            <p>{{ $t('chooseAForm') }}</p>
            <img src="@/includes/shapes/arrow_button.svg" @click="addEdges" v-bind:class="(edges > 9) ? 'disabled' : ''">
        </div>

        <v-text-field class="initals" v-model="initials" :rules="rules" :label="$t('yourInitials')" maxlength="2" required></v-text-field>

        <div class="color-selection">
            <label for="">{{ $t('chooseAColor') }}</label>
            <br>
            <div class="hexagon-radios">
                <div v-for="(c, index) in colors" :key="index">
                    <input type="radio" :value="index" :style="{ background: colors[index] }" name="hexagon-radio" v-model="color" />
                    <br v-if="index == 3">
                </div>
            </div>
        </div>
    </v-form>

    <v-btn class="send-button" @click="sendAvatar" color="pink" size="large">{{ $t('toTheGame') }}</v-btn>
</template>

<script>
export default {
    name: 'AvatarCustomization',
    emits: ["avatarData"],
    data() {
        return {
            initials: '',
            color: 0,
            colors: [
                '#FA64FA',
                '#0096FA',
                '#FFC800',
                '#96C8C8',
                '#FA6464',
                '#64C896',
                '#966496',
                '#DCDC78',
                '#FAAA32'
            ],
            edges: 4,
            polygons: [
                "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                "polygon(50% 0%, 100% 38%, 82% 95%, 18% 95%, 0% 38%)",
                "polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%)",
                "polygon(50% 0%, 90% 20%, 100% 60%, 75% 95%, 25% 95%, 0% 60%, 10% 20%)",
                "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                "polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)",
                "polygon(50% 0%, 80% 10%, 98% 35%, 98% 65%, 80% 90%, 50% 100%, 20% 90%, 2% 65%, 2% 35%, 20% 10%)",
            ],
            rules: [
                value => {
                    if (value) return true

                    return 'Required'
                },
            ],
        }
    },
    methods: {
        reduceEdges() {
            this.edges = (this.edges > 4) ? this.edges - 1 : this.edges;
        },

        addEdges() {
            this.edges = (this.edges < 10) ? this.edges + 1 : this.edges;
        },

        sendAvatar() {
            this.$refs.entryForm.validate();
            if (this.initials == '') return;
            this.$emit('avatarData', { "i": this.initials, "e": this.edges, "c": this.color });
        }
    },
}
</script>