export default {
  // English translations
  home: "Home",
  yourDataPixel: "Your Data Pixel",
  correct: "correct",
  explanation: "Explanation",
  explanation_top: "The joystick is your key to controlling the movement of your data pixel.",
  explanation_bottom: "To move, simply press down on the inner circle, hold down, and slide the circle in the direction you want your data pixel to go.",
  showHelp: "Show Help",
  understood: "Understood",
  stopPlaying: "Stop Playing",
  quitGame: "Leave",
  finishPlaying: "Finish playing?",
  back: "Back",
  startQuiz: "Guess now",
  quizDescription:
    "You have already learned a lot about data and its origin. The quiz, where you can test your knowledge and collect many points, takes place on the LED wall above you.",
  createYourDataPixel: "Create your Data Pixel",
  chooseAForm: "Choose a Shape",
  yourInitials: "Your Initials",
  chooseAColor: "Choose a Color",
  toTheGame: "To the Game",
  yourScore: 'Your Score',
  noCorrectAnswers: 'Unfortunately, you did not answer any questions correctly.',
  oneCorrectAnswer: 'Congratulations! You answered 1 question correctly.',
  multipleCorrectAnswers: 'Congratulations! You answered {score} out of {questionCount} questions correctly.',
  finishMessage: "Do you want to leave the game?",
  point: "Point",
  points: "Points",
  correct2: "Correct", 
  anwer: "Answer",
  sorry: "Sorry",
  wrong: "Wrong",
  soon: "Stay",
  continue: "tuned",
  thankYou: "Thank you for participating in our data quiz game! We hope you had a great time at the House of Digitalisation.",
  playingField: "Playing Field",
  playingFieldMessage: "Your avatar appears at right end of the LED wall and can now be controlled by you.",
  playingFieldButton: "Let's play",
};
