export default {
  // German translations
  home: "Startseite",
  yourDataPixel: "Dein Datenpixel",
  correct: "richtig",
  explanation: "Erklärung",
  explanation_top: "Mit dem Joystick steuerst du dein Datenpixel.",
  explanation_bottom: "Um es zu bewegen, drücke einfach auf den inneren Kreis, halte den Kreis gedrückt und schiebe ihn gleichzeitig in die Richtung, in die sich dein Datenpixel bewegen soll.",
  showHelp: "Hilfe anzeigen",
  understood: "Verstanden",
  stopPlaying: "Spiel verlassen",
  quitGame: "Aufhören",
  finishPlaying: "Aufhören zu spielen?",
  back: "Zurück",
  startQuiz: "Jetzt Mitraten",
  quizDescription:
    "Du hast schon einiges über Daten und Ihren Ursprung erfahren. Das Quiz, bei dem du dein Wissen testen und viele Punkte sammeln kannst, findet auf der LED-Fläche über dir statt.",
  createYourDataPixel: "Erstelle dein Datenpixel",
  chooseAForm: "Wähle eine Form",
  yourInitials: "Deine Initialen",
  chooseAColor: "Wähle eine Farbe",
  toTheGame: "Zum Spiel",
  noCorrectAnswers: 'Leider hast du keine Frage richtig beantwortet.',
  oneCorrectAnswer: 'Gratuliere! Du hast 1 Frage richtig beantwortet.',
  multipleCorrectAnswers: 'Gratuliere! Du hast {score} von {questionCount} Fragen richtig beantwortet.',
  finishMessage: "Möchtest du nicht mehr am Spiel teilnehmen?",
  yourScore: 'Dein Score',
  point: "Punkt",
  points: "Punkte",
  correct2: "Richtige", 
  answer: "Antwort",
  sorry: "Leider",
  wrong: "Falsch",
  soon: "Gleich geht",
  continue: "es weiter",
  thankYou: "Vielen Dank für die Teilnahme an unserem Daten-Quizspiel! Wir hoffen, Sie haben eine tolle Zeit im Haus der Digitalisierung verbracht.",
  playingField: "Spielfeld",
  playingFieldMessage: "Dein Avatar taucht rechts oben, am Ende der LED-Fläche auf und kann nun von dir gesteuert werden.",
  playingFieldButton: "Los geht's"
};
